/** @jsx jsx */

// import html2canvas from 'html2canvas'
// import { shuffle } from 'lodash'

import { useEffect, useState } from 'react'
import { jsx } from 'theme-ui'

import Chip from '../components/chip'
import Footer from '../components/footer'
import Header from '../components/header'
import { faces } from '../utilities'
import Halo from './halo'

const Home = () => {
  const [assets, setAssets] = useState([])

  useEffect(() => {
    fetch(
      'https://api.opensea.io/api/v1/assets?order_direction=desc&offset=0&limit=20&collection=test-collection-of-items',
      {
        method: 'GET',
        headers: { 'X-API-KEY': `8c2bf5d741ea4b7482265ea28d7825aa` },
      },
    )
      .then((response) => response.json())
      .then((response) => {
        // console.log(response)
        setAssets(response.assets)
      })
      .catch((error) => console.error(error))
  }, [])

  // useEffect(() => {
  //   html2canvas(document.querySelector('#character-11110')).then(function (canvas) {
  //     document.querySelector('#canvas').appendChild(canvas)
  //   })
  // }, [])

  return (
    <div sx={{ display: `grid`, fontFamily: `body`, gap: 3 }}>
      <Halo />
      <Header />

      <section sx={{ py: 5, px: [`0.5rem`, `0.75rem`], bg: `sectionBackground` }}>
        <div sx={{ maxWidth: 800, mx: `auto` }}>
          <h1
            sx={{
              my: 0,
              fontWeight: `600`,
              fontSize: [`2rem`, `2.5rem`, `3rem`],
              letterSpacing: `-0.02em`,

              // fontFamily: `heading`,
              // fontWeight: `500`,
              // letterSpacing: `-0.035em`,
            }}
          >
            Character characters
          </h1>

          <p sx={{ mt: 2, mb: 4, fontSize: [`1rem`, `1.25rem`, `1.5rem`] }}>
            Generative avatars created with unicode characters
          </p>

          <div
            sx={{
              my: 3,
              display: `grid`,
              gridTemplateColumns: `repeat(auto-fit, minmax(264px, 1fr))`,
              gap: `0.25rem`,
            }}
          >
            <div
              sx={{
                p: 2,
                display: `grid`,
                alignItems: `end`,
                color: `characterPrimaryText`,
                bg: `characterPrimaryBackground`,
              }}
            >
              Claimed
            </div>

            <div sx={{ p: 2, display: `grid`, alignItems: `end`, color: `white`, bg: `royalblue` }}>Unclaimed</div>
            {/* <div sx={{ p: 2, display: `grid`, alignItems: `end`, color: `white`, bg: `red` }}>Dropping soon</div> */}
            <div sx={{ p: 2, display: `grid`, alignItems: `end`, color: `black`, bg: `silver` }}>Not yet available</div>
          </div>
        </div>
      </section>

      <main sx={{ py: 4, px: [`0.5rem`, `0.75rem`], bg: `sectionBackground` }}>
        <div sx={{ maxWidth: 800, mx: `auto` }}>
          <ul
            sx={{
              my: 0,
              pl: 0,
              listStyleType: `none`,
              fontFamily: `monospace`,
              justifyContent: `space-between`,
              gap: `0.25rem`,
              display: `grid`,
              gridTemplateColumns: `repeat(auto-fill, minmax(130px, 1fr))`,
            }}
          >
            {faces
              // .slice(0, 60) // TODO: Remove
              .map((character) => (
                <Chip key={character.code} {...character} />
              ))}

            {/* {assets
              .filter((a) => a.image_preview_url)
              .map((asset) => (
                <Chip key={asset.image_preview_url} {...asset} />
              ))} */}
          </ul>
        </div>
      </main>

      <Footer />
    </div>
  )
}

export default Home
