/** @jsx jsx */

import { Router } from '@reach/router'
import { jsx } from 'theme-ui'

import Character from '../components/character'
import Collection from '../components/collection'
import Home from '../components/home'

// import Raw from '../components/raw'

const Index = () => {
  return (
    <Router>
      <Home path="/" />
      <Character path="/characters:id" />
      <Collection path="/collections:id" />
      {/* <Collection path="/raw:id" /> */}
    </Router>
  )
}

export default Index
